
import {html2json} from "html2json";
import wpfix from '../wpautop'
const pageQuery = `


fragment pageData on Page {
  id
  slug
  excerpt
  title
  seo {
    fullHead
    metaRobotsNoindex
    metaRobotsNofollow
  }
  content(format: RAW)
  uri
  status
  template {
    templateName
  }
  hreflang
  thumbnail
  largeTitle
  smallTitle
  productId
  virtualProductId
  headerSize
  season
  episode
  headerPosition
  mask
  pageId
  sellingPoints
  geolocationCity
  region
  categories {
    nodes {
      name
      uri
    }
  }
  tags {
    nodes {
      name
      uri
    }
  }
}

  query GetContentNode($id: ID!) {
    page(id: $id, idType: URI) {

      ... on Page {
        ...pageData
      }

    }
  }
`
const jobQuery=`query MyQuery($id: String!) {
  jobs(where: {job_link: {_eq: $id}}) {
    modifiedGmt: updated_at
    geolocationCity: geolocation_city
    geolocationCountryLong: geolocation_country_long
    geolocationCountryShort: geolocation_country_short
    geolocationLat: geolocation_lat
    geolocationLong: geolocation_long
    date: created_at
    jobId: id
    jtype: contract_type
    joblength: job_duration
    accomodation: accommodation
    reference: job_reference
    vacancies
    hours: certificate_hours
    school: school_type
    location
    tagline: company_tagline
    title
    content: description
    salary: salary
    salary_per
    salary_currency
    salary_number
    uri: job_link
    degree: degree_required
    id: id
    thumbnail
    expiry:application_deadline
    instructions: application_instructions
    start_date_type
    start_date
    age_group
    company_name
    certificate_hours
    other_requirements
    job_duration_number
    additional_benefits
    experience
    application_email
  }
}`

export function state() {
  return {
    pageInfo: {
      endCursor: '',
      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: '',
    },
    page: {
      id: '',
      slug: '',
      title: '',
      content: '',
      excerpt: '',
      seo:'',
      uri: '',
      status: '',
      template:'',
      thumbnail:'',
      largeTitle:'',
      smallTitle:'',
      hreflang:'',
      region:'uk',
      categories:'',
      tags:'',
    },
    activeTab:null,
    activeModule: null,
    activeModal: null,
    activeClassroomTab: null,
    activeBundleTab: null,
    activeFaq: null,
  }
}

export const mutations = {

  setPage(state, page) {
    state.page = page
  },
  setPageInfo(state, pageInfo) {
    state.pageInfo = pageInfo
  },
  setActiveTab(state, tabInfo) {
    state.activeTab = tabInfo
  },
  setActiveModule(state, moduleInfo) {
    state.activeModule = moduleInfo
  },
  setActiveModal(state, modalInfo) {
    state.activeModal = modalInfo
  },
  setActiveClassroomTab(state, classroomTabInfo) {
    state.activeClassroomTab = classroomTabInfo
  },
  setActiveBundleTab(state, bundleTabInfo) {
    state.activeBundleTab = bundleTabInfo
  },
  setActiveFaq(state, faqInfo) {
    state.activeFaq = faqInfo
  },

}

export const actions = {
    async getPage({
      commit
    },
    slug
  ) {

    const result = await this.$wp(pageQuery,
       {
        id: slug,
      }
    )


  const page=result?.page


  const cleanHtml=page.seo.fullHead.replace(/\n\s*/ig, "").replace(/<!--(.*?)-->/ig,"")
  // const cleanHref=page.hreflang.replace(/\n\s*/ig, "").replace(/<!--(.*?)-->/ig,"")
  page.seo.fullHead = html2json(cleanHtml)
  page.hreflang = JSON.parse(page.hreflang)
  page.content = await wpfix(page.content)

    commit('setPage', page)
    return page
  },
  async getJob({
    commit
  },
  slug
) {
  // console.log(jobQuery,slug)
  const result = await this.$job(jobQuery,
     {
      id: '/'+slug.replace(/\/?$/, '/'),
    }
  )

console.log(result)
  const page=result?.jobs[0]
page.template={templateName:"Default"}

  // const cleanHtml=page.seo.fullHead.replace(/\n\s*/ig, "").replace(/<!--(.*?)-->/ig,"")
  // page.seo.fullHead = html2json(cleanHtml)
  // if(page.content)
  // page.content = await wpfix(page.content)

    commit('setPage', page)
    return page
  },

  updateActiveTab({commit}, tabInfo) {
    // window.rudderanalytics.track("Tab Click")
    commit('setActiveTab', tabInfo)
    return tabInfo
  },

  updateActiveModule({commit}, moduleInfo) {
    // window.rudderanalytics.track("Module Click")
    commit('setActiveModule', moduleInfo)
    return moduleInfo
  },

  updateActiveModal({commit}, modalInfo) {
    // window.rudderanalytics.track("Modal Click")
    commit('setActiveModal', modalInfo)
    return modalInfo
  },

  updateActiveClassroomTab({commit}, classroomTabInfo) {
    // window.rudderanalytics.track("ClassroomTab Toggle Click")
    commit('setActiveClassroomTab', classroomTabInfo)
    return classroomTabInfo
  },

  updateActiveBundleTab({commit}, bundleTabInfo) {
    // window.rudderanalytics.track("Bundletab Toggle Click")
    commit('setActiveBundleTab', bundleTabInfo)
    return bundleTabInfo
  },

  updateActiveFaq({commit}, faqInfo) {
    // window.rudderanalytics.track("Faq Click")
    commit('setActiveFaq', faqInfo)
    return faqInfo
  },
}
/*
export const getters = () => ({

  getTemplate: (state) => {
    return state.page.template.templateName
  },
  getTabs: (state) => {
    return state.page.openTabs
  },
})
*/

export const getters = {
  getTemplate: (state) => {
    return state.page.template.templateName
  },
  getActiveTab: (state) => {
    return state.activeTab
  },
  getActiveModule: (state) => {
    return state.activeModule
  },
  getActiveModal: (state) => {
    return state.activeModal
  },
  getActiveClassroomTab: (state) => {
    return state.activeClassroomTab
  },
  getActiveBundleTab: (state) => {
    return state.activeBundleTab
  },
  getActiveFaq: (state) => {
    return state.activeFaq
  },
  getPageRegion: (state) => {
    return state.page.region
  },
  getPage: (state) => {
    return state.page.slug
  },
  getCategory: (state) => {
    let category = ''
    console.log('NODES',state.page.categories !== '' &&  state.page.categories?.nodes?.length > 0)
    if(state.page.categories !== '' && state.page.categories?.nodes?.length > 0){
      category = state.page.categories.nodes[0].name
    }
    return category
  },
  getTags: (state) => {
    let tag = ''
    console.log('NODES',state.page.tags !== '' &&  state.page.tags?.nodes?.length > 0)
    if(state.page.tags !== '' && state.page.tags?.nodes?.length > 0){
      tag = state.page.tags.nodes[0].name
    }
    return tag
  }


}
